export class BaseApiService {
    /**
     * @param path
     * @returns {Promise<Response>}
     */
    get(path, params, opts = {}) {
        let headers = this.getHeaders();
        let options = {
            ...{
                method: 'GET',
                headers: {
                    ...{
                        'Content-Type': 'application/json'
                    }, ...headers
                }
            },
            ...opts
        };

        return fetch(this.parsePath(path, params), options)
            .then(this.resolveResponse)
            .catch(this.handleError)
    }

    /**
     * @param path
     * @param data
     * @returns {Promise<Response>}
     */
    post(path, data, opts = {}) {
        let headers = this.getHeaders();

        let options = {
            ...{
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors', // no-cors, *cors, same-origin
                // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin', // include, *same-origin, omit
                // credentials: "include",
                headers: {
                    ...{
                        'Content-Type': 'application/json'
                    }, ...headers
                },
                // redirect: 'follow', // manual, *follow, error
                // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            },
            ...opts
        };

        return fetch(this.parsePath(path), options).then(this.resolveResponse)
    }

    /**
     * @param path
     * @param data
     * @returns {Promise<Response>}
     */
    put(path, data) {
        return this.post(path, data, {
            method: 'PUT'
        });
    }

    /**
     * @param path
     * @returns {Promise<Response>}
     */
    delete(path) {
        return this.get(path, null, { method: 'DELETE' });
    }

    /**
     * @param path
     * @param data
     * @returns {Promise<Response>}
     */
    deleteMultiple(path, data) {
        return this.post(path, data, {
            method: 'DELETE'
        });
    }

    /**
     * @param res
     * @returns {Promise<unknown>}
     */
    resolveResponse(res) {
        return new Promise((resolve, reject) => {
            if (res.status >= 200 && res.status < 300) {
                res.json().then((data) => {
                    resolve(data);
                })
                return;
            }

            if (res.statusText) {
                res.json().then((data) => {
                    let error = new Error(res.statusText || res.status)
                    error.response = data
                    reject(error);
                })
            } else {
                let error = new Error(res.statusText || res.status)
                error.response = ''
                reject(error);
            }
        })
    }

    /**
     * @param error
     */
    handleError(error) {
        console.error('Error', error)
    }

    /**
     * @param obj
     * @returns {string}
     */
    serializeQueryString = function (obj) {
        const str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    /**
     * @param path
     * @param params
     * @returns {*}
     */
    parsePath(path, params) {
        const baseApi = window.location.host === 'swimlapcount.test' ?
            'https://swimlapcount.test/api' : process.env.REACT_APP_BASE_API;

        let url = baseApi + // Base URL
            ((baseApi.match(/\/$/) || path.match(/^\//)) ? '' : '/') + // Slash if is needed
            path;

        const serializedParams = this.serializeQueryString(params);
        if (serializedParams) {
            url = url + '?' + serializedParams;
        }

        return url;
    }

    /**
     * @returns {{}}
     */
    getHeaders() {
        return {};
    }
}
