import PropTypes from "prop-types";
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import t from '../../mockTranslation';
import Swimmer from '../../shared/model/Swimmer';

class EditSwimmer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            currentSwimmer: props.currentSwimmer,
            units: [],
            loading: !props.currentSwimmer,
            validated: true
        };
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            return;
        }
        this.props.onSave(this.props.currentSwimmer);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const currentSwimmer = this.state.currentSwimmer;
        currentSwimmer[name] = value;

        this.setState({
            currentSwimmer: currentSwimmer
        });
    }

    handleCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        if (this.props.onSave === undefined)
            return ''
        // const {t} = this.props;
        const currentSwimmer = this.state.currentSwimmer;

        return (<>
            <Form noValidate onSubmit={this.handleSubmit.bind(this)} data-bs-theme="dark">
                <Form.Group>
                    <Form.Control required type="text" placeholder={t('Nuevo equipo')}
                                  value={currentSwimmer.name} name={'name'}
                                  size="sm"
                                  onChange={this.handleInputChange.bind(this)}/>
                </Form.Group>
                <Button type="submit" className="me-2" size="sm" variant="primary">{t('Guardar')}</Button>
                <Button type="button" className="me-2" size="sm" variant="secondary"
                        onClick={this.handleCancel.bind(this)}>{t('Cancelar')}</Button>
            </Form>
        </>);
    }
}

export default EditSwimmer;

EditSwimmer.propTypes = {
    currentSwimmer: PropTypes.instanceOf(Swimmer),
    onCancel: PropTypes.func,
    onSave: function (props, propName, componentName) {
        const fn = props[propName];
        if (!fn ||
            !fn.prototype ||
            (typeof fn.prototype.constructor !== 'function' &&
                fn.prototype.constructor.length !== 1)) {
            return new Error(propName + ' must be a function with 1 arg');
        }
    }
}
