import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import './AppGrid.css';
import {ConfirmationModal} from './components/ConfirmationModal';
import EditSwimmer from './components/Swimmer/EditSwimmer'
import ApiService from './shared/db/ApiService';
import Games from './shared/db/Games';
import Game from './shared/model/Game';
import Swimmer from './shared/model/Swimmer';

function App() {
    const games = new Games();
    const apiService = new ApiService();

    const [currentSwimmer, setCurrentSwimmer] = useState({});
    const [editableSwimmer, setEditableSwimmer] = useState(null);
    const [items, setItems] = useState([]);
    const [game, setGame] = useState(new Game());
    const [showForm, setShowForm] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);

    useEffect(function () {
        const cg = games.currentGame();
        setGame(cg);
        setCurrentSwimmer(cg.getCurrentSwimmer())
        apiService.listenSwimmers()
            .subscribe((items) => {
                setItems(items)
            });
        reloadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(function () {
        setShowForm(showCreateForm)
    }, [showCreateForm]);

    useEffect(function () {
        game.setCurrentSwimmer(currentSwimmer);
        setShowCreateForm(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSwimmer]);

    const saveGame = useCallback(() => {
        setGame(previousState => {
            return Object.assign(new Game(), {...previousState, ...game})
        });
        games.setCurrentGame(game);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game]);

    const reloadData = () => {
        apiService.listSwimmers().then((items) => {
            setItems(items)
        });
    };

    const upScore = function (row) {
        row.laps++;
        apiService.addLap(row).then(() => {
            // reloadData();
        });
    }

    const downScore = function (row) {
        if (row.laps <= 0) {
            return;
        }
        row.laps--;
        apiService.removeLap(row).then(() => {
            // reloadData();
        });
    }

    const changeCurrentSwimmer = (row) => {
        setCurrentSwimmer(row);
        game.setCurrentSwimmer(row)
        saveGame()
    };

    const showCreateSwimmer = () => {
        setEditableSwimmer(new Swimmer())
        setShowCreateForm(true);
    };

    const onSave = function (item) {
        closeSwimmerForm()
        apiService.saveSwimmer(item)
            .then((items) => {
                setItems(items)
            })
    }

    const closeSwimmerForm = function (item) {
        setShowCreateForm(false);
    }

    const editSwimmer = function (row) {
        setEditableSwimmer(row);
        setShowCreateForm(true);
    }

    const removeSwimmer = async function (row) {
        if (await ConfirmationModal(`Estás a punto de borrar al equipo "${row.name}". Este proceso no es reversible, confirme para continuar.`)) {
            apiService.removeSwimmer(row)
                .then((items) => {
                    setItems(items)
                })
        }
    }

    return (
        <div className="App">
            <div className="App-header">
                {!showForm &&
                    <div onClick={() => showCreateSwimmer()}
                         className={classNames("CreateSwimmer-Action")}>
                        🏊
                    </div>}

                {showCreateForm &&
                    <EditSwimmer currentSwimmer={editableSwimmer} onSave={onSave} onCancel={closeSwimmerForm}/>}
            </div>
            <div className="App-items">
                {items && items.map((row, key) => {
                    return <div key={key}
                                className={classNames("Swim-Lane", {"Active": currentSwimmer && currentSwimmer.id === row.id})}
                                onClick={() => changeCurrentSwimmer(row)}>
                        <div className="Score">
                            <div className="Score-Value">{row.laps}</div>
                            {/*<div className="Score-Time">{row.bestLap} / {row.lastLap}</div>*/}
                        </div>
                        <div className="White-Divisor">&nbsp;</div>
                        <div className="Score-Name">
                            <div>
                                <span>{row.name}</span>
                            </div>
                        </div>
                    </div>
                })}
            </div>
            <div className="App-footer">
                {currentSwimmer && currentSwimmer.id > 0 && <>
                    <div onClick={() => editSwimmer(currentSwimmer)}
                         className={classNames("EditSwimmer-Action", "Active")}>
                        ✏️
                    </div>
                    <div onClick={() => removeSwimmer(currentSwimmer)}
                         className={classNames("RemoveSwimmer-Action", "Active")}>
                        🗑️
                    </div>
                    <div onClick={() => downScore(currentSwimmer)}
                         className={classNames("DownScore-Action", "Active")}>
                        ➖
                    </div>
                    <div onClick={() => upScore(currentSwimmer)}
                         className={classNames("UpScore-Action", "Active")}>
                        ➕
                    </div>
                </>}
            </div>
        </div>
    );
}

export default App;
