import Game from '../model/Game';
import Db from './Db';

class Games extends Db {
    currentGame() {
        let cg = this.get('current_game')
        if (cg !== null) {
            cg = Object.assign(new Game(), cg);
        }
        if (cg === null) {
            cg = new Game();
            this.set('current_game', cg)
        }
        return cg;
    }

    setCurrentGame(cg) {
        this.set('current_game', cg)
        return this;
    }
}

export default Games;