const lockWakeState = async () => {
    const canWakeLock = () => 'wakeLock' in navigator;

    if (!canWakeLock()) {
        return;
    }

    let wakelock;

    try {
        wakelock = await navigator.wakeLock.request();
        wakelock.addEventListener('release', () => {
            console.log('Screen Wake State Locked:', !wakelock.released);
        });
        console.log('Screen Wake State Locked:', !wakelock.released);
    } catch (e) {
        console.error('Failed to lock wake state with reason:', e.message);
    }
}

export default lockWakeState;
