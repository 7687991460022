class Swimmer {
    id = 0;

    name = '';

    laps = 0;

    constructor(name = '') {
        this.name = name;
    }

    addLap() {
        this.laps++;
        return this;
    }
}

export default Swimmer;