import Swimmer from './Swimmer';

class Game {
    selectedSwimmer = null;

    currentTime = null;

    items = null;

    constructor() {
        // [
        //     { id: 1, name: 'Nadador Uno', laps: [] },
        //     { id: 2, name: 'Nadador Dos', laps: [] },
        //     { id: 3, name: 'Nadador Tres', laps: [] }
        // ].map(row => {
        //     return Object.assign(new Swimmer(), row);
        // })
    }

    setItems(items) {
        this.items = items;
        return this;
    }

    /**
     *
     * @param {Swimmer} currentSwimmer
     */
    setCurrentSwimmer(currentSwimmer) {
        this.selectedSwimmer = currentSwimmer;
    }

    /**
     *
     * @returns {Swimmer|null}
     */
    getCurrentSwimmer() {
        return Object.assign(new Swimmer(), this.selectedSwimmer);
    }
}

export default Game;