import {Observable} from 'rxjs';
import {io} from 'socket.io-client';
import {BaseApiService} from './BaseApiService';

class ApiService extends BaseApiService {
    saveSwimmer(item) {
        return new Promise((resolve, reject) => {
            this.post('swimmers', item).then(() => {
                this.listSwimmers().then((items) => {
                    resolve(items)
                })
            })
        })
    }

    removeSwimmer(item) {
        return new Promise((resolve, reject) => {
            this.delete('swimmers/' + item.id).then(() => {
                this.listSwimmers().then((items) => {
                    resolve(items)
                })
            })
        })
    }

    listSwimmers() {
        return new Promise((resolve, reject) => {
            this.get('swimmers').then((data) => {
                resolve(data.data);
            })
        })
    }

    addLap(swimmer) {
        return new Promise((resolve, reject) => {
            this.post('swimmers/laps/' + swimmer.id).then((data) => {
                resolve(data.data);
            })
        })
    }

    removeLap(swimmer) {
        return new Promise((resolve, reject) => {
            this.delete('swimmers/laps/' + swimmer.id).then((data) => {
                resolve(data.data);
            })
        })
    }

    listenSwimmers() {
        return new Observable(observer => {
            const socket = io('https://swimlapcountconsumer.alberdi525.duckdns.org', {
                rejectUnauthorized: false
            })

            socket.on("swimlap", (data) => {
                observer.next(JSON.parse(data));
            });
            socket.on("connect_error", (error) => {
                console.error(error)
            });
        });
    }
}

export default ApiService;